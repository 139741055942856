import DataTable from "react-data-table-component";
import { LuArrowBigLeft, LuArrowBigRight } from "react-icons/lu";
import './orderDetails.scss'
import {HiTruck} from 'react-icons/hi'
import user from '../../../../Assets/imgs/user.png'
import { BsTelephone } from "react-icons/bs";
import { dateFormate } from "../../../../Components/Common/dateFormate";
import DriverAssignTable from "./driverAssignTable";
import MapV2 from "../../../../Components/Map/mapv2";
import defImg from '../../../../Assets/imgs/Banner.svg'

export const Items = (row,t)=>(
    <>
        <div className="items-wrapper capitalize">
            <div className="flex justify-between items-start">
                <div className={`min-w-[60px] ${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-3' : 'mr-3'}`}>
                    <img src={row?.itemImage ? row?.itemImage : defImg} alt={localStorage.getItem('i18nextLng') === 'ar' ? row?.itemNameAr : row?.itemName } className="w-[60px] h-[60px] rounded-lg" />
                </div>

                <div>
                    <p className={`text-[#333] font-semibold text-[14px] mb-2 ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : null}`}>{localStorage.getItem('i18nextLng') === 'ar' ? row?.itemNameAr : row?.itemName}</p>
                    {/* <p className="px-4 py-2 text-[#E09156] bg-[#FFF4DE] w-fit rounded-md mb-2 font-normal text-sm">spicy</p> */}

                    <ul className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'order-details-item-list-ar' : 'order-details-item-list'} capitalize mt-2 ml-3`}>
                        <p className={`text-mainColor font-semibold mb-2 capitalize ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : null} `}>{t('menu.add_new_dish.form.options.form.title')}</p>
                        {(row?.options && row?.options?.length !== 0) ?
                            row?.options?.map ( item=>(
                                <li className={`flex ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : null} [&>span]:block [&>span]:mr-1 items-center`}>
                                    
                                    <>
                                        <span className={`adson-title min-w-[40px]`}>{localStorage.getItem('i18nextLng') === 'ar' ? item?.nameAr : item?.name}</span> 
                                        <span>{localStorage.getItem('i18nextLng') === 'ar' ? <LuArrowBigLeft /> : <LuArrowBigRight /> }</span>
                                        <span title={row?.name} className="adson-desc max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap">{item?.quantity} X</span>
                                        <span className={`adson-desc mx-2`}>{item?.price} {t('common.sar')}</span>
                                    </>
                                
                                </li>
                            ) )
                            
                        : <p className="text-[#9e9e9e] font-semibold mb-2 capitalize text-sm">{t('deals.form.options.noOptions')}</p> }
                    </ul>
                    
                    <ul className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'order-details-item-list-ar' : 'order-details-item-list'} capitalize mt-2 ml-3`}>
                        <p className={`text-mainColor font-semibold mb-2 capitalize ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : null}`}>{t('deals.form.extras.title')}</p>

                        {row?.extras && row?.extras?.length !==0 ? 
                            <li className={`flex [&>span]:block  items-start extras-list ${localStorage.getItem('i18nextLng') === 'ar' ? '[&>span]:ml-2' : '[&>span]:mr-2'}`}>
                                <div className="flex flex-wrap">
                                    {row?.extras?.map( item=>(
                                        <div className="flex items-center flex-wrap grow" key={item?.id}>
                                            <span className={`adson-title min-w-[40px]`}>{localStorage.getItem('i18nextLng') === 'ar' ? item?.nameAr : item?.name}</span> 
                                            <span>{localStorage.getItem('i18nextLng') === 'ar' ? <LuArrowBigLeft /> : <LuArrowBigRight /> }</span>
                                            <span title={row?.name} className="adson-desc max-w-[180px] overflow-hidden text-ellipsis whitespace-nowrap">{item?.quantity} X</span>
                                            <span className={`adson-desc ${localStorage.getItem('i18nextLng') === 'ar' ? 'mr-2' : 'ml-2'}`}>{item?.price} {t('common.sar')}</span>
                                        </div>
                                    ) )}
                                </div>
                            </li> 
                        : <p className="text-[#9e9e9e] font-semibold mb-2 capitalize text-sm">{t('deals.form.extras.noExtras')}</p>}

                    </ul>
                </div>
            </div>
        </div>
    </>
)

const OrderDetailsRightSection = ({data, t}) => {
// console.log(data);
    const customStyles = {
        headRow: {
            style: {
            borderBottom: '1px solid #ddd',
            marginBottom : '8px',
            
            },
        },
        headCells: {
            style: {
                color: '#979797',
                fontSize: '14px',
                position : 'relative',
            },
        },
        rows: {
            highlightOnHoverStyle: {
                backgroundColor: '#64aaef20',
                borderBottomColor: '#FFFFFF',
                borderRadius: '5px',
                outline: '1px solid #FFFFFF',
            },
            style:{
                padding : '8px 0px',
                fontWeight : '600',
                fontSize : '16px',
                marginBottom : '15px'
            }
        },
        pagination: {
            style: {
                border: 'none',
            },
        },
    };

    const columns = [
        // {
        //     name: 'ID',
        //     selector: row => row.id,
        //     width:'70px'
        //     // sortable: true,
        // },
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'العناصر' : 'Items'}`,
            // width:'auto',
            minWidth:'420px',
            maxWidth:'550px',
            // sortable: true,
            cell: (row)=>Items(row,t)
        },
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'السعر' : 'Price'}`,
            selector: row => row.itemPrice + " " + t('common.sar'),
            sortable: false,
        },
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'الكميه' : 'Quantity'}`,
            selector: row => row.itemQuantity,
            sortable: false,
        },
        {
            name: `${localStorage.getItem('i18nextLng') === 'ar' ? 'اجمالي السعر' : 'Total Price'}`,
            selector: row => ((parseFloat(row?.itemPrice) + (row?.options ? row?.options?.reduce((acc, item) => acc + (item.price * item.quantity), 0) : 0) + (row?.extras ? row?.extras?.reduce((acc, item)=>{return parseFloat(acc) + parseFloat(item?.price)},0) : 0)) * parseFloat(row?.itemQuantity) ) + " " + t('common.sar'),
            // selector: row => parseFloat(row?.itemPrice) * parseFloat(row?.itemQuantity) + ' $',
            sortable: false,
        },
        // {
        //     name: 'Action',
        //     allowOverflow: true,
        //     button : true,
        //     cell: (row)=> <IconButton><IoIosCloseCircleOutline className="text-mainRed" /></IconButton>
                
        // },
    ];

    return ( 
        <div className="right-section-wrapper">
            <div className="order-details-right-section-top-wrapper bg-white p-4 rounded-lg">
                <div>
                    <h2 className={`text-[#464E5F] font-semibold text-lg ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : null}`}>{t('order_details.right_section.items_title')}</h2>
                </div>

                <div className="order-details-data-table-items-wrapper w-full min-h-[340px]">
                    <DataTable
                        direction={localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}
                        columns={columns}
                        data={data?.orderItems}
                        pagination
                        paginationPerPage={2}
                        paginationRowsPerPageOptions={[2,10,50,100]}
                        customStyles={customStyles}
                        highlightOnHover
                        className='w-full'
                        paginationComponentOptions={
                            {
                                rowsPerPageText : t('common.paginationRowText')
                            }
                        }
                    />
                </div>
            </div>

            {/* {data?.orderType === "DELIVERY" ?  */}
                <>
                    <div className="order-item-map-wrapper mt-8 bg-white p-4 rounded-lg">
                        <div style={{ height: '250px', width: '100%' }}>

                            {/* <MapComponent data={data} /> */}
                            <MapV2 data={data} />
                                
                        </div>
                    </div>

                    {(data?.orderStatus === 'NEW' || data?.orderStatus === 'PICKED_UP' || data?.orderStatus === 'ABANDONED' || data?.orderStatus === 'CANCELLED' || data?.orderStatus === 'REJECTED' ) ? 
                        null :
                        <> 
                            <div className="my-4 bg-white p-4 delivery-info-wrapper w-full rounded-lg capitalize">
                                <div className="w-full grid grid-cols-1 md:grid-cols-3 justify-between items-center">

                                    <div className="flex flex-wrap items-center">
                                        <div className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`}><img src={user} alt="user" className="w-10 h-10 rounded-[50%]" /></div>
                                        <div>
                                            <p className={`text-[#B5B5C3] text-[12px] ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : 'text-left'}`}>{t('order_details.right_section.courier.name')}</p>
                                            <p className="text-[14px] font-semibold text-[#464E5F]">{data?.courierName}</p>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap items-center">
                                        <div className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`}>
                                            <span className="bg-mainColor w-10 h-10 rounded-[50%] text-center flex flex-col justify-center"><BsTelephone className="text-2xl text-white m-auto" /></span>
                                        </div>
                                        <div>
                                            <p className={`text-[#B5B5C3] text-[12px] ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : 'text-left'}`}>{t('order_details.right_section.courier.telephone')}</p>
                                            <p className="text-[14px] font-semibold text-[#464E5F]">{data?.courierMobileNumber}</p>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap items-center">
                                        <div className={`${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}`}>
                                            <span className="bg-mainColor w-10 h-10 rounded-[50%] text-center flex flex-col justify-center"><HiTruck className="text-2xl text-white m-auto" /></span>
                                        </div>
                                        <div>
                                            <p className={`text-[#B5B5C3] text-[12px] ${localStorage.getItem('i18nextLng') === 'ar' ? 'text-right' : 'text-left'}`}>{t('order_details.right_section.courier.delivery_time')}</p>
                                            <p className="text-[14px] font-semibold text-[#464E5F]" dir="ltr">{dateFormate(data?.deliveredAt)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="driver-assign-table">
                                <DriverAssignTable data={data} t={t} />
                            </div>
                        </>
                    }
                </> 
                
                {/* : 
                <div className="font-semibold w-full min-h-[250px] flex justify-center items-center capitalize">
                    
                    {t('order_details.right_section.no_map')}
                </div> 
            } */}

            
        </div>
    );
}

export default OrderDetailsRightSection;